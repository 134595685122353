import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import SEO from '../src/helpers/seo';
import TitleH2 from '../src/components/Titles/TitleH2/TitleH2';
import { Freya } from '../src/components/Freya/Freya';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';

import './medio_ambiente.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query medio_ambiente($locale: String!) {
    allMedioAmbienteSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allMedioAmbienteImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMedioAmbienteTitleHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allMedioAmbienteImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allMedioAmbienteFreyaBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          characteristics
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allMedioAmbienteTopPromoted(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          buttons {
            cta
            ctaText
            size
            color
          }
        }
      }
    }
    allMedioAmbienteRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class MedioAmbiente extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allMedioAmbienteTitleHeader.edges[0].node.title,
      image: {
        url: this.props.data.allMedioAmbienteImageHeader.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allMedioAmbienteImageHeader.edges[0].node.alt,
      },
    };

    const titleData = {
      title: this.props.data.allMedioAmbienteTitleHeader.edges[0].node.description,
    };
    const subtitleData = {
      title: this.props.data.allMedioAmbienteImage.edges[0].node.title,
    };

    const PrepareFreya = src => ({
      title: tt('NUESTRA RESPONSABILIDAD CORPORATIVA', this.props.pageContext.locale),
      isSlider: true,
      cards: src.edges
        .map(e => ({
          image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
          imgsize: 'promoted',
          title: e.node.title,
          buttons: {
            size: 'alone',
            color: 'white',
            ctaText: tt('MÁS INFO', this.props.pageContext.locale),
            popup: {
              enable: true,
              textpop: e.node.characteristics,
            },
          },
        }))
        .filter(e => e.image !== undefined),
    });

    const freyaData = {
      title: tt('RESPONSABILIDAD CORPORATIVA', this.props.pageContext.locale),
      cards: this.props.data.allMedioAmbienteTopPromoted.edges.map(promotion => ({
        image: promotion.node.localImage.childImageSharp.fluid,
        altImage: promotion.node.image_alt,
        title: promotion.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: promotion.node.buttons.cta,
          size: 'alone',
          color: 'white',
          ctaText: promotion.node.buttons.ctaText,
        },
      })),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allMedioAmbienteSeoBlock.edges[0].node._0.title}
          description={this.props.data.allMedioAmbienteSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allMedioAmbienteRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allMedioAmbienteImageHeader.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="medioambiente-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
              />
              <TitleH2 data={titleData} />
              <div className="mid-container">
                <div className="text-container">
                  <H3 data={subtitleData} />
                  <div
                    className="inner-container"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.allMedioAmbienteImage.edges[0].node.description,
                    }}
                  />
                </div>
                <div className="image-container">
                  <Img
                    fluid={
                      this.props.data.allMedioAmbienteImage.edges[0].node.localImage.childImageSharp
                        .fluid
                    }
                    alt={this.props.data.allMedioAmbienteImage.edges[0].node.image_alt}
                  />
                </div>
              </div>
              <Freya data={PrepareFreya(this.props.data.allMedioAmbienteFreyaBlock)} />
              <H3 data={freyaData} />
              <Freya data={freyaData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default MedioAmbiente;
